// Define a mapping object for IDs to labels
const idToLabelMap = {
    card_instrument: 'Card',
    netbanking_instrument: 'Netbanking',
    upi_instrument: 'UPI',
    wallet_india_instrument: 'Wallet',
    virtual_account_dynamic_instrument: 'Virtual Account',
    // Add more mappings as needed
}

const kcpTypeToLabelMap = {
    card: 'Credit Card & E-Wallet',
    bank: 'Bank Transfer',
}

export function paymentTypeIdToLabel(idString, pspResponse) {
    // Validate the input against the mapping object
    if (idString in idToLabelMap) {
        return idToLabelMap[String(idString)]
    } else if (idString === 'kcp_instrument' && pspResponse.type in kcpTypeToLabelMap) {
        return kcpTypeToLabelMap[pspResponse.type]
    } else {
        // Handle invalid or unknown IDs
        return 'Unknown'
    }
}
